const { REACT_APP_API_URL } = process.env;

const urls = {
  local: "http://localhost:8081",
  staging: "https://api-staging.mynota.io",
  production: "https://reimagined-api.mynota.io",
};

type ApiUrlType = "local" | "staging" | "production";
const getUrl = (type: ApiUrlType) => {
  if (
    process.env.REACT_APP_ENV === "production" ||
    process.env.REACT_APP_ENV === "staging"
  ) {
    return REACT_APP_API_URL;
  }

  return urls[type];
};

const getApiUrl = (type: ApiUrlType) => {
  return `${getUrl(type)}/api`;
};

export default getApiUrl;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { CircleCheck, X } from "tabler-icons-react";
import { resetPassword } from "../../api/apiClient";
import { TextInput } from "../../components";
import {
  Card,
  colors,
  Spacer,
  Tooltip,
  Button,
} from "../../components/library";
import usePasswordValidation from "../../hooks/usePasswordValidation";
import "./passwordResetPage.scss";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PasswordResetPage = () => {
  const query = useQuery();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const {
    validData,
    PasswordValidationTooltipContent,
    ConfirmPasswordValidationTooltipContent,
  } = usePasswordValidation(password, confirmPassword);

  const { innerWidth: width, innerHeight: height } = window;

  const onSubmit = async () => {
    const token = query.get("t");
    if (token) {
      const result = await resetPassword(password, token);
      if (result.uuid) {
        // uuid of the returned user object
        setSuccess(true);
      } else {
        console.log(result);
        setError(
          result.statusCode === 500
            ? "This link is expired, please request a new one in the My Nota app."
            : "Reset failed: You have not requested a password reset."
        );
      }
    }
  };

  return (
    <div className="container">
      <div className="page password-reset-page">
        <Card className={"password-reset-card"}>
          {!success ? (
            <>
              <div className={"name-title-section"}>
                <h1>Reset Password</h1>
                <p>Enter a new password below.</p>
              </div>
              <div className={"name-input"}>
                {/* <p>Password</p> */}
                <Tooltip
                  placement={width > 767 ? "right" : "bottom"}
                  title={"Password Must Be:"}
                  actionArea={
                    <TextInput
                      label="Password"
                      placeholder="********"
                      value={password}
                      onChange={setPassword}
                      type={"password"}
                    />
                  }
                >
                  <PasswordValidationTooltipContent />
                </Tooltip>

                <Spacer size={6} />
                {/* <p>Confirm Password</p> */}
                <Tooltip
                  placement={width > 767 ? "right" : "bottom"}
                  actionArea={
                    <TextInput
                      label="Confirm Password"
                      placeholder="********"
                      value={confirmPassword}
                      onChange={setConfirmPassword}
                      type={"password"}
                    />
                  }
                >
                  <ConfirmPasswordValidationTooltipContent />
                </Tooltip>
              </div>
              <Spacer size={6} />
              <Button
                text="Continue"
                onClick={onSubmit}
                disabled={!validData.isValid}
              />
              {!!error && <p className="error-text">{error}</p>}
            </>
          ) : (
            <div className={"name-title-section"}>
              <h1>Success!</h1>
              <p>Your password was changed successfully.</p>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default PasswordResetPage;

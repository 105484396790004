import React, { useEffect, useState } from "react";
import logo from "../../../logoBlack.png";
import { InterstitialResource } from "../../../types/resource.types";
import { LogoHorizontal } from "../../../components/library/logos";
import { colors, LoaderSpinner } from "../../../components/library";

interface InterstitialQuestionProps {
  resource: InterstitialResource;
  goForward: () => void;
}

//Time between animations
const animationInterval = 5000;
const animationIntervalStr = `${animationInterval}ms`;
//Animation duration
const animationDuration = 500;
const animationDurationStr = `${animationDuration}ms`;

const InterstitialStep = ({
  resource,
  goForward,
}: InterstitialQuestionProps) => {
  const [isLeaving, setIsLeaving] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLeaving(true);
      setTimeout(() => {
        goForward();
      }, animationDuration);
    }, animationInterval);
  }, []);

  return (
    <>
      <div
        className={`animation-fade ${isLeaving && "leaving"}`}
        style={{ animationDuration: animationDurationStr, height: "30px" }}
      >
        <LogoHorizontal color={colors.primary[4]} />
      </div>
      <div
        className={`assessment-step interstitial animation-fade ${
          isLeaving && "leaving"
        }`}
        style={{ animationDuration: animationDurationStr }}
      >
        <h1 style={{ textAlign: "center" }}>{resource.data.text}</h1>
        <LoaderSpinner />
      </div>
    </>
  );
};

export default InterstitialStep;

import "./textInput.scss";

export interface TextInputProps {
  className?: string;
  placeholder?: string;
  label?: string;
  value: string;
  type?: string;
  autoFocus?: boolean;
  onChange: (placeholder: string) => void;
}

const TextInput = ({
  className = "",
  placeholder = "",
  label = "",
  onChange,
  type = "text",
  value = "",
  autoFocus,
}: TextInputProps) => {
  return (
    <label className="text-input-wrapper">
      {label}
      <input
        placeholder={placeholder}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        value={value}
        type={type}
        className={`text-input ${className}`}
        autoFocus={autoFocus}
      />
    </label>
  );
};

export default TextInput;

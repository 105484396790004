import { useEffect, useState } from "react";
import { CircleCheck, X } from "tabler-icons-react";
import { colors } from "../components/library";

const usePasswordValidation = (password: string, confirmPassword: string) => {
  const [validData, setValidData] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    numbers: false,
    same: false,
    isValid: false,
  });

  useEffect(() => {
    const passwordIsValidData = validatePassword();
    setValidData(passwordIsValidData);
  }, [password, confirmPassword]);

  const validatePassword = () => {
    const passwordData = {
      length: password.length > 8,
      lowercase: !!password.match(/[a-z]+/g),
      uppercase: !!password.match(/[A-Z]+/g),
      numbers: !!password.match(/[0-9]+/g),
      same: password === confirmPassword,
    };
    return {
      ...passwordData,
      isValid:
        passwordData.length &&
        passwordData.numbers &&
        passwordData.lowercase &&
        passwordData.uppercase &&
        passwordData.same,
    };
  };

  const PasswordValidationTooltipContent = () => {
    return (
      <div className={"password-validation-box"}>
        <div>
          {validData.length ? (
            <CircleCheck color={colors.success[4]} />
          ) : (
            <X color={colors.error[4]} />
          )}{" "}
          at least 8 characters
        </div>
        <div>
          {validData.uppercase ? (
            <CircleCheck color={colors.success[4]} />
          ) : (
            <X color={colors.error[4]} />
          )}{" "}
          at least 1 uppercase
        </div>
        <div>
          {validData.lowercase ? (
            <CircleCheck color={colors.success[4]} />
          ) : (
            <X color={colors.error[4]} />
          )}{" "}
          at least 1 lowercase
        </div>
        <div>
          {validData.numbers ? (
            <CircleCheck color={colors.success[4]} />
          ) : (
            <X color={colors.error[4]} />
          )}{" "}
          at least 1 number
        </div>
      </div>
    );
  };

  const ConfirmPasswordValidationTooltipContent = () => {
    return (
      <div className={"password-validation-box confirm-popup"}>
        <div>
          {validData.same && validData.isValid ? (
            <CircleCheck color={colors.success[4]} />
          ) : (
            <X color={colors.error[4]} />
          )}{" "}
          Passwords are the same
        </div>
      </div>
    );
  };
  return {
    validData,
    PasswordValidationTooltipContent,
    ConfirmPasswordValidationTooltipContent,
  };
};

export default usePasswordValidation;

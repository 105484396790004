import "./tooltip.scss";
import React, { PropsWithChildren } from "react";
import { colors, TextInput } from "../index";
import { CircleCheck, X } from "tabler-icons-react";

export interface TooltipProps {
  placement: "right" | "left" | "top" | "bottom";
  actionArea: any;
  title?: string;
  content?: any;
}

const Tooltip = ({
  placement = "right",
  actionArea,
  children,
  title,
  content,
}: PropsWithChildren<TooltipProps>) => {
  return (
    <div className="tooltip">
      {actionArea}
      <div className={placement}>
        <i></i>
        {title && <h5>{title}</h5>}
        {content ? content : children}
      </div>
    </div>
  );
};

export default Tooltip;
